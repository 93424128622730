import React from 'react'

const test = () => {
    return (
        <>
            <div
                className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-3 lg:gap-x-5 xl:gap-x-7 gap-y-3 xl:gap-y-5 2xl:gap-y-8"
            >
                <div className="mb-12 md:mb-14 xl:mb-16 border border-gray-300 rounded-md pt-5 md:pt-6 lg:pt-7 pb-5 lg:pb-7 px-4 md:px-5 lg:px-7">
                    <div className="flex justify-between items-center flex-wrap mb-5 md:mb-6">
                        <div className="flex items-center justify-between -mt-2 mb-0">
                            <h3 className="text-lg md:text-xl lg:text-2xl 2xl:text-3xl xl:leading-10 font-bold text-heading">
                                Flash Sale
                            </h3>
                        </div>
                        <div className="flex items-center space-s-1.5 md:space-s-2.5">
                            <div className="text-heading text-10px md:text-xs text-center uppercase">
                                <span className="bg-heading rounded-md text-white text-xs md:text-sm w-8 h-8 md:w-10 md:h-10 flex items-center justify-center mb-1">
                                    87
                                </span>
                                days
                            </div>
                            <div className="text-heading text-10px md:text-xs text-center uppercase">
                                <span className="bg-heading rounded-md text-white text-xs md:text-sm w-8 h-8 md:w-10 md:h-10 flex items-center justify-center mb-1">
                                    3
                                </span>
                                hours
                            </div>
                            <div className="text-heading text-10px md:text-xs text-center uppercase">
                                <span className="bg-heading rounded-md text-white text-xs md:text-sm w-8 h-8 md:w-10 md:h-10 flex items-center justify-center mb-1">
                                    50
                                </span>
                                mins
                            </div>
                            <div className="text-heading text-10px md:text-xs text-center uppercase">
                                <span className="bg-heading rounded-md text-white text-xs md:text-sm w-8 h-8 md:w-10 md:h-10 flex items-center justify-center mb-1">
                                    35
                                </span>
                                secs
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-x-3 md:gap-x-5 xl:gap-x-7 gap-y-4 lg:gap-y-5 xl:gap-y-6 2xl:gap-y-8">
                        <div
                            className="group box-border overflow-hidden flex rounded-md cursor-pointer  bg-white pe-0 md:pb-1 flex-col items-start"
                            role="button"
                            title="Adidas Shoes Black"
                        >
                            <div className="flex mb-3 md:mb-3.5 pb-0">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27324%27%20height=%27324%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="Adidas Shoes Black"
                                        src="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-26-md.png&w=750&q=100"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        className="bg-gray-300 object-cover rounded-s-md rounded-md transition duration-150 ease-linear transform group-hover:scale-105"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                        srcSet="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-26-md.png&w=384&q=100 1x, /_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-26-md.png&w=750&q=100 2x"
                                    />
                                </span>
                                <div className="absolute top-3.5 md:top-5 3xl:top-7 start-3.5 md:start-5 3xl:start-7 flex flex-col gap-y-1 items-start" />
                            </div>
                            <div className="w-full overflow-hidden p-2 ps-0">
                                <h2 className="truncate mb-1 font-semibold md:mb-1.5 text-sm sm:text-base md:text-sm lg:text-base xl:text-lg text-heading">
                                    Adidas Shoes Black
                                </h2>
                                <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate">
                                    Men Black top sleeveless gown
                                </p>
                                <div
                                    className="font-semibold text-sm sm:text-base mt-1.5 space-s-2 sm:text-xl md:text-base lg:text-xl md:mt-2.5 2xl:mt-3
     text-heading"
                                >
                                    <span className="inline-block false">$45.00</span>
                                    <del className="sm:text-base font-normal text-gray-800">$99.99</del>
                                </div>
                            </div>
                        </div>
                        <div
                            className="group box-border overflow-hidden flex rounded-md cursor-pointer  bg-white pe-0 md:pb-1 flex-col items-start"
                            role="button"
                            title="Armani Wide-Leg Trousers"
                        >
                            <div className="flex mb-3 md:mb-3.5 pb-0">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27324%27%20height=%27324%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="Armani Wide-Leg Trousers"
                                        src="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-16-md.png&w=750&q=100"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        className="bg-gray-300 object-cover rounded-s-md rounded-md transition duration-150 ease-linear transform group-hover:scale-105"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                        srcSet="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-16-md.png&w=384&q=100 1x, /_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-16-md.png&w=750&q=100 2x"
                                    />
                                </span>
                                <div className="absolute top-3.5 md:top-5 3xl:top-7 start-3.5 md:start-5 3xl:start-7 flex flex-col gap-y-1 items-start" />
                            </div>
                            <div className="w-full overflow-hidden p-2 ps-0">
                                <h2 className="truncate mb-1 font-semibold md:mb-1.5 text-sm sm:text-base md:text-sm lg:text-base xl:text-lg text-heading">
                                    Armani Wide-Leg Trousers
                                </h2>
                                <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate">
                                    Monochrome elegance. Made with a relaxed wide-leg, these trousers are
                                    made from a sustainable soft organic cotton with a mechanical stretch
                                    making the garment easily recycled.
                                </p>
                                <div
                                    className="font-semibold text-sm sm:text-base mt-1.5 space-s-2 sm:text-xl md:text-base lg:text-xl md:mt-2.5 2xl:mt-3
     text-heading"
                                >
                                    <span className="inline-block false">$12.00</span>
                                    <del className="sm:text-base font-normal text-gray-800">$16.00</del>
                                </div>
                            </div>
                        </div>
                        <div
                            className="group box-border overflow-hidden flex rounded-md cursor-pointer  bg-white pe-0 md:pb-1 flex-col items-start"
                            role="button"
                            title="Zara Shoes Green"
                        >
                            <div className="flex mb-3 md:mb-3.5 pb-0">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27324%27%20height=%27324%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="Zara Shoes Green"
                                        src="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-3.png&w=750&q=100"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        className="bg-gray-300 object-cover rounded-s-md rounded-md transition duration-150 ease-linear transform group-hover:scale-105"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                        srcSet="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-3.png&w=384&q=100 1x, /_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-3.png&w=750&q=100 2x"
                                    />
                                </span>
                                <div className="absolute top-3.5 md:top-5 3xl:top-7 start-3.5 md:start-5 3xl:start-7 flex flex-col gap-y-1 items-start" />
                            </div>
                            <div className="w-full overflow-hidden p-2 ps-0">
                                <h2 className="truncate mb-1 font-semibold md:mb-1.5 text-sm sm:text-base md:text-sm lg:text-base xl:text-lg text-heading">
                                    Zara Shoes Green
                                </h2>
                                <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate">
                                    Footwear refers to garments worn on the feet, which originally serves
                                    to purpose of protection against adversities of the environment,
                                    usually regarding ground textures and temperature.
                                </p>
                                <div
                                    className="font-semibold text-sm sm:text-base mt-1.5 space-s-2 sm:text-xl md:text-base lg:text-xl md:mt-2.5 2xl:mt-3
     text-heading"
                                >
                                    <span className="inline-block false">$50.00</span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="group box-border overflow-hidden flex rounded-md cursor-pointer  bg-white pe-0 md:pb-1 flex-col items-start"
                            role="button"
                            title="Wayfarer Sunglasses"
                        >
                            <div className="flex mb-3 md:mb-3.5 pb-0">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27324%27%20height=%27324%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="Wayfarer Sunglasses"
                                        src="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-25-md.png&w=750&q=100"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        className="bg-gray-300 object-cover rounded-s-md rounded-md transition duration-150 ease-linear transform group-hover:scale-105"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                        srcSet="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-25-md.png&w=384&q=100 1x, /_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-25-md.png&w=750&q=100 2x"
                                    />
                                </span>
                                <div className="absolute top-3.5 md:top-5 3xl:top-7 start-3.5 md:start-5 3xl:start-7 flex flex-col gap-y-1 items-start" />
                            </div>
                            <div className="w-full overflow-hidden p-2 ps-0">
                                <h2 className="truncate mb-1 font-semibold md:mb-1.5 text-sm sm:text-base md:text-sm lg:text-base xl:text-lg text-heading">
                                    Wayfarer Sunglasses
                                </h2>
                                <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate">
                                    Our optical engineers developed these sunglasses for hiking. Ideal for
                                    occasional use in the mountains.
                                </p>
                                <div
                                    className="font-semibold text-sm sm:text-base mt-1.5 space-s-2 sm:text-xl md:text-base lg:text-xl md:mt-2.5 2xl:mt-3
     text-heading"
                                >
                                    <span className="inline-block false">$15.00</span>
                                    <del className="sm:text-base font-normal text-gray-800">$18.00</del>
                                </div>
                            </div>
                        </div>
                        <div
                            className="group box-border overflow-hidden flex rounded-md cursor-pointer  bg-white pe-0 md:pb-1 flex-col items-start"
                            role="button"
                            title="Tissot Classic"
                        >
                            <div className="flex mb-3 md:mb-3.5 pb-0">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27324%27%20height=%27324%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="Tissot Classic"
                                        src="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-30-md.png&w=750&q=100"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        className="bg-gray-300 object-cover rounded-s-md rounded-md transition duration-150 ease-linear transform group-hover:scale-105"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                        srcSet="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-30-md.png&w=384&q=100 1x, /_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-30-md.png&w=750&q=100 2x"
                                    />
                                </span>
                                <div className="absolute top-3.5 md:top-5 3xl:top-7 start-3.5 md:start-5 3xl:start-7 flex flex-col gap-y-1 items-start" />
                            </div>
                            <div className="w-full overflow-hidden p-2 ps-0">
                                <h2 className="truncate mb-1 font-semibold md:mb-1.5 text-sm sm:text-base md:text-sm lg:text-base xl:text-lg text-heading">
                                    Tissot Classic
                                </h2>
                                <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate">
                                    The new-model Submariner now features Rolex’s powerhouse calibre 3235
                                    Perpetual movement. An upgrade from the calibre 3135 movement,
                                </p>
                                <div
                                    className="font-semibold text-sm sm:text-base mt-1.5 space-s-2 sm:text-xl md:text-base lg:text-xl md:mt-2.5 2xl:mt-3
     text-heading"
                                >
                                    <span className="inline-block false">$600.00</span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="group box-border overflow-hidden flex rounded-md cursor-pointer  bg-white pe-0 md:pb-1 flex-col items-start"
                            role="button"
                            title="Hermes Carlton London"
                        >
                            <div className="flex mb-3 md:mb-3.5 pb-0">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27324%27%20height=%27324%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="Hermes Carlton London"
                                        src="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-14-md.png&w=750&q=100"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        className="bg-gray-300 object-cover rounded-s-md rounded-md transition duration-150 ease-linear transform group-hover:scale-105"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                        srcSet="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-14-md.png&w=384&q=100 1x, /_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-14-md.png&w=750&q=100 2x"
                                    />
                                </span>
                                <div className="absolute top-3.5 md:top-5 3xl:top-7 start-3.5 md:start-5 3xl:start-7 flex flex-col gap-y-1 items-start" />
                            </div>
                            <div className="w-full overflow-hidden p-2 ps-0">
                                <h2 className="truncate mb-1 font-semibold md:mb-1.5 text-sm sm:text-base md:text-sm lg:text-base xl:text-lg text-heading">
                                    Hermes Carlton London
                                </h2>
                                <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate">
                                    Off-White self-striped knitted midi A-line dress, has a scoop neck,
                                    sleeveless, straight hem
                                </p>
                                <div
                                    className="font-semibold text-sm sm:text-base mt-1.5 space-s-2 sm:text-xl md:text-base lg:text-xl md:mt-2.5 2xl:mt-3
     text-heading"
                                >
                                    <span className="inline-block false">$15.00</span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="group box-border overflow-hidden flex rounded-md cursor-pointer  bg-white pe-0 md:pb-1 flex-col items-start"
                            role="button"
                            title="Polarised Wayfarer Sunglasses"
                        >
                            <div className="flex mb-3 md:mb-3.5 pb-0">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27324%27%20height=%27324%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="Polarised Wayfarer Sunglasses"
                                        src="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-27-md.png&w=750&q=100"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        className="bg-gray-300 object-cover rounded-s-md rounded-md transition duration-150 ease-linear transform group-hover:scale-105"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                        srcSet="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-27-md.png&w=384&q=100 1x, /_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-27-md.png&w=750&q=100 2x"
                                    />
                                </span>
                                <div className="absolute top-3.5 md:top-5 3xl:top-7 start-3.5 md:start-5 3xl:start-7 flex flex-col gap-y-1 items-start" />
                            </div>
                            <div className="w-full overflow-hidden p-2 ps-0">
                                <h2 className="truncate mb-1 font-semibold md:mb-1.5 text-sm sm:text-base md:text-sm lg:text-base xl:text-lg text-heading">
                                    Polarised Wayfarer Sunglasses
                                </h2>
                                <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate">
                                    This item is only exchangeable for the same or a different size, if
                                    available, and cannot be returned
                                </p>
                                <div
                                    className="font-semibold text-sm sm:text-base mt-1.5 space-s-2 sm:text-xl md:text-base lg:text-xl md:mt-2.5 2xl:mt-3
     text-heading"
                                >
                                    <span className="inline-block false">$20.00</span>
                                    <del className="sm:text-base font-normal text-gray-800">$35.00</del>
                                </div>
                            </div>
                        </div>
                        <div
                            className="group box-border overflow-hidden flex rounded-md cursor-pointer  bg-white pe-0 md:pb-1 flex-col items-start"
                            role="button"
                            title="Gucci Carlton UK"
                        >
                            <div className="flex mb-3 md:mb-3.5 pb-0">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27324%27%20height=%27324%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="Gucci Carlton UK"
                                        src="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-8-md.png&w=750&q=100"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        className="bg-gray-300 object-cover rounded-s-md rounded-md transition duration-150 ease-linear transform group-hover:scale-105"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                        srcSet="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-8-md.png&w=384&q=100 1x, /_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-8-md.png&w=750&q=100 2x"
                                    />
                                </span>
                                <div className="absolute top-3.5 md:top-5 3xl:top-7 start-3.5 md:start-5 3xl:start-7 flex flex-col gap-y-1 items-start" />
                            </div>
                            <div className="w-full overflow-hidden p-2 ps-0">
                                <h2 className="truncate mb-1 font-semibold md:mb-1.5 text-sm sm:text-base md:text-sm lg:text-base xl:text-lg text-heading">
                                    Gucci Carlton UK
                                </h2>
                                <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate">
                                    Knitted midi A-line dress, has a scoop neck, sleeveless, straight hem
                                </p>
                                <div
                                    className="font-semibold text-sm sm:text-base mt-1.5 space-s-2 sm:text-xl md:text-base lg:text-xl md:mt-2.5 2xl:mt-3
     text-heading"
                                >
                                    <span className="inline-block false">$14.99</span>
                                    <del className="sm:text-base font-normal text-gray-800">$19.99</del>
                                </div>
                            </div>
                        </div>
                        <div
                            className="group box-border overflow-hidden flex rounded-md cursor-pointer  bg-white pe-0 md:pb-1 flex-col items-start"
                            role="button"
                            title="NIKE Shoes"
                        >
                            <div className="flex mb-3 md:mb-3.5 pb-0">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27324%27%20height=%27324%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="NIKE Shoes"
                                        src="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-24-md.png&w=750&q=100"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        className="bg-gray-300 object-cover rounded-s-md rounded-md transition duration-150 ease-linear transform group-hover:scale-105"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                        srcSet="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-24-md.png&w=384&q=100 1x, /_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-24-md.png&w=750&q=100 2x"
                                    />
                                </span>
                                <div className="absolute top-3.5 md:top-5 3xl:top-7 start-3.5 md:start-5 3xl:start-7 flex flex-col gap-y-1 items-start" />
                            </div>
                            <div className="w-full overflow-hidden p-2 ps-0">
                                <h2 className="truncate mb-1 font-semibold md:mb-1.5 text-sm sm:text-base md:text-sm lg:text-base xl:text-lg text-heading">
                                    NIKE Shoes
                                </h2>
                                <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate">
                                    NIKE 2020 Black White is a clean and monochromatic colourway of the
                                    label’s latest high-technology silhouette. The model first launched
                                    late last year and is currently Jordan Brand’s flagship performance
                                    pair.
                                </p>
                                <div
                                    className="font-semibold text-sm sm:text-base mt-1.5 space-s-2 sm:text-xl md:text-base lg:text-xl md:mt-2.5 2xl:mt-3
     text-heading"
                                >
                                    <span className="inline-block false">$50.00</span>
                                    <del className="sm:text-base font-normal text-gray-800">$80.00</del>
                                </div>
                            </div>
                        </div>
                        <div
                            className="group box-border overflow-hidden flex rounded-md cursor-pointer  bg-white pe-0 md:pb-1 flex-col items-start"
                            role="button"
                            title="Wayfarer Sunglasses"
                        >
                            <div className="flex mb-3 md:mb-3.5 pb-0">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27324%27%20height=%27324%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="Wayfarer Sunglasses"
                                        src="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-12-md.png&w=750&q=100"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        className="bg-gray-300 object-cover rounded-s-md rounded-md transition duration-150 ease-linear transform group-hover:scale-105"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                        srcSet="/_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-12-md.png&w=384&q=100 1x, /_next/image?url=%2Fassets%2Fimages%2Fproducts%2Fp-12-md.png&w=750&q=100 2x"
                                    />
                                </span>
                                <div className="absolute top-3.5 md:top-5 3xl:top-7 start-3.5 md:start-5 3xl:start-7 flex flex-col gap-y-1 items-start" />
                            </div>
                            <div className="w-full overflow-hidden p-2 ps-0">
                                <h2 className="truncate mb-1 font-semibold md:mb-1.5 text-sm sm:text-base md:text-sm lg:text-base xl:text-lg text-heading">
                                    Wayfarer Sunglasses
                                </h2>
                                <p className="text-body text-xs lg:text-sm leading-normal xl:leading-relaxed max-w-[250px] truncate">
                                    Our optical engineers developed these sunglasses for hiking. Ideal for
                                    occasional use in the mountains.
                                </p>
                                <div
                                    className="font-semibold text-sm sm:text-base mt-1.5 space-s-2 sm:text-xl md:text-base lg:text-xl md:mt-2.5 2xl:mt-3
     text-heading"
                                >
                                    <span className="inline-block false">$20.00</span>
                                    <del className="sm:text-base font-normal text-gray-800">$25.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default test