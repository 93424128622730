import React from 'react';
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import apiClient from '../../../api/http-common';
import { useHistory } from 'react-router-dom';
const contactSupplier = (props) => {
    const history = useHistory();
    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            name: '',
            phone_no: '',
            email: '',
            messages: '',
            product_id: props.Viewproduct.id,
        }
    });
    const onSubmit = (data) => {
        apiClient.post(`/v1/customer/order/supplierContact`, data)
            .then(res => {
                console.log(res)
                toast.success(`${res.data}`);
                localStorage.removeItem("CartProduct")
                history.push('/')
            })
            .catch(err => {
                console.log(err);
            })
    };
    // console.log(props.Viewproduct);
    return (
        <div>
            <ToastContainer autoClose={900} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                    <input type="text" {...register("name", { required: true })} class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Phone Numner</label>
                    <input {...register("phone_no", { required: true })} class="form-control" id="exampleFormControlTextarea1" rows="3" />
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Email</label>
                    <input {...register("email", { required: true })} class="form-control" id="exampleFormControlTextarea1" rows="3" />
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                    <textarea {...register("messages", { required: true })} class="form-control" id="exampleFormControlTextarea1" rows="3" />
                </div>

                <input class="form-control btn btn-info" type="submit" />
            </form>
        </div>
    )
}

export default contactSupplier
