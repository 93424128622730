import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

class Processstep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Row className="mb-2">
        <Col lg={4} sm={6}  >
          <Link to='/userRequest'>
            <div className="d-flex">
              <div className="mr-2">
                <i className="las la-hand-holding-usd ic-2x text-primary" />
              </div>
              <div>
                <h5 className="mb-1">Request for Quotation</h5>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={4} sm={6} className="mt-3 mt-sm-0">
          <div className="d-flex">
            <div className="mr-2">
              <i className="las la-lock ic-2x text-primary" />
            </div>
            <a href='https://flingex.com/' target="_blank">
              <div>
                <h5 className="mb-1">Ready to Ship</h5>
              </div>
            </a>

          </div>
        </Col>
        <Col lg={4} sm={6} className="mt-3 mt-lg-0">
          <div className="d-flex">
            <div className="mr-2">
              <i className="las la-truck ic-2x text-primary" />
            </div>
            <a href='https://flingex.com/' target="_blank">
              <div>
                <h5 className="mb-1">Logistics services</h5>
              </div>
            </a>
          </div>
        </Col>

      </Row>
    );
  }
}

export default Processstep;