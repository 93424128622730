import React from 'react';
import { Row, Col, Container, Card, CardHeader, CardBody, option } from 'reactstrap';
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import apiClient from '../../api/http-common';
import { useHistory } from 'react-router-dom';

const userRequest = () => {
    const history = useHistory();
    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            productid: '',
            budget_range: '',
            quantity: '',
            company_name: '',
            business_type: '',
            business_email: '',
            business_phone: '',

        }
    });
    const onSubmit = (data) => {
        apiClient.post(`v1/quotation/apply`, data)
            .then(res => {
                console.log(res)
                toast.success(`${res.data}`);
                localStorage.removeItem("CartProduct")
                history.push('/')
            })
            .catch(err => {
                console.log(err);
            })
    };
    return (
        <div>

            <Container className='mb-2 mt-3'>
                <Card>
                    <CardHeader className='bg-info'>
                        Tell us what you are looking for
                    </CardHeader>
                    <CardBody>
                        <ToastContainer autoClose={900} />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Product Name</label>
                                <input type="text" {...register("productid", { required: true })} class="form-control" id="exampleFormControlInput1" placeholder="Product Name" />
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Budget range</label>
                                <select  {...register("budget_range", { required: true })} className="form-control" id="exampleFormControlInput1" >
                                    <option selected>Open this select menu</option>
                                    <option value="500-1000">
                                        500-1000
                                    </option>
                                    <option value="2000-4000">
                                        2000-4000
                                    </option>
                                    <option value="5000-10000">
                                        5000-10000
                                    </option>
                                    <option value="10000-up">
                                        10000-up
                                    </option>

                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Quantity</label>
                                <input {...register("quantity", { required: true })} class="form-control" id="exampleFormControlTextarea1" rows="3" />
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Company name</label>
                                <input {...register("company_name", { required: true })} class="form-control" id="exampleFormControlTextarea1" rows="3" />
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Business Type</label>
                                <input {...register("business_type", { required: true })} class="form-control" id="exampleFormControlTextarea1" rows="3" />
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Business email</label>
                                <input {...register("business_email", { required: true })} class="form-control" id="exampleFormControlTextarea1" rows="3" />
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Business phone</label>
                                <input {...register("business_phone", { required: true })} class="form-control" id="exampleFormControlTextarea1" rows="3" />
                            </div>
                            <input class="form-control btn btn-info" type="submit" />
                        </form>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default userRequest
